import { createStore } from "vuex"
import axios from "axios";


export default createStore({
  state: {
    access: "",
    refresh: "",
    user: "",
  },
  mutations: {
    initializeStore(state) {
      let refresh = localStorage.getItem("refreshtoken");
      if (refresh) {
        state.access = "";
        state.refresh = refresh;
      } else {
        state.access = ""
        state.refresh = ""
      }
    },
    setAccess(state, data) {
      state.access = data.access,
      state.user = data.user ;
    },
    setRefreshToken(state, refresh) {
      state.refresh = refresh;
      localStorage.setItem("refreshtoken", refresh)
    },
    clearAuth(state){
      state.access = '';
      state.refresh = '';
      state.user = '';
      localStorage.removeItem("access");
      localStorage.removeItem("refreshtoken");

    }  },
  actions: {},
  modules: {},
})
