<template>
  <div>
    <Nav />
    <div class="centered">
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "@/components/Nav";
// import * as store from '@/store/index.ts'
export default {
  components: { Nav },
  name: "App",
  async beforeCreate() {
    this.$store.commit("initializeStore");
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
