import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      access: {
        allowGuest: true,
      },
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/locations",
    name: "Locations",

    component: () => import(/* webpackChunkName: "locations" */ "../views/Locations.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
  {
    path: "/newlocation",
    name: "NewLocation",

    component: () => import(/* webpackChunkName: "locations" */ "../views/NewLocation.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
  {
    path: "/newcontractor",
    name: "NewContractor",

    component: () => import(/* webpackChunkName: "locations" */ "../views/NewContractor.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
  {
    path: "/",
    name: "Home",

    // component: () => import(/* webpackChunkName: "locations" */ "../views/Locations.vue"),
    component: () => import(/* webpackChunkName: "locations" */ "../views/Home.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
  {
    path: "/locations/details/:id/",
    name: "LocationDetails",

    component: () => import(/* webpackChunkName: "locations" */ "../views/LocationDetails.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
  {
    path: "/defect/:id/",
    name: "DefectDetails",

    component: () => import(/* webpackChunkName: "locations" */ "../views/DefectDetails.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
  {
    path: "/contractors",
    name: "Contractors",

    component: () => import(/* webpackChunkName: "locations" */ "../views/Contractors.vue"),
    props: true,
    meta: {
      access: {
        allowGuest: false,
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { access } = to.meta;
  // @ts-ignore
  let allowGuest = access?.allowGuest;

  // console.log("Cur access token: ", store.state.access);
  // console.log("Cur refresh token: ", store.state.refresh);
  if (store.state.access && store.state.access.length > 0) {
    return next();
  }

  if (store.state.refresh && store.state.refresh.length > 0) {
    let access = await getAccess(store.state.refresh);
    if (access && access.length > 0) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + access;
      return next();
    } else {
      return next({ path: "/login" });
    }
  } else if (allowGuest) {
    return next();
  } else {
    return next({ path: "/login" });
  }
});

import axios from "axios";

async function getUser(accessToken: string): Promise<string> {
  console.log("accessToken at nav:", accessToken);
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  const res = await axios.get("/user/");
  return res.data.username;
}

async function getAccess(refresh: String) {
  const accessData = {
    refresh: refresh,
  };
  try {
    let response = await axios.post("api/token/refresh/", accessData);
    const access = response.data.access;
    var user = await getUser(access);
    store.commit("setAccess", { access, user });
    return access;
  } catch (error) {
    var errorString = error.toString();

    if (errorString === "Error: Request failed with status code 401") {
      console.log("USER IS NOT AUTHORIZED");
      store.state.refresh = "";
    }
  }
}

export default router;
