
import { reactive } from "vue";
import { useRouter } from "vue-router";
import store from "@/store/index";
import axios from "axios";

export default {
  name: "Login",
  setup() {
    const data = reactive({
      username: "",
      password: "",
    });
    const router = useRouter();

    const submit = async () => {
      const res = await axios.post("/api/token/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        // credentials: 'include',
        // data: JSON.stringify(data),
        username: data.username,
        password: data.password,
      });
      const token = res.data;
      // console.log("RES:", await res)
      console.log("RES access:", token.access, token.refresh);
      store.commit("setAccess", token.access);
      store.commit("setRefreshToken", token.refresh);

      await router.push("/");
    };

    return {
      data,
      submit,
    };
  },
};
