import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from 'primevue/config';
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";
// import * as store from "./store/index";
import store from "./store/index";
// import Notifications from "@kyvg/vue3-notification";


const app = createApp(App);
app.use(PrimeVue);


import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

axios.defaults.baseURL = "https://construction-reporter.programowanieodkuchni.pl/";
// axios.defaults.baseURL = "http://127.0.0.1:8000/";  // for debugging
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("access");
// createApp(App).use(store.default).use(router, axios)
// .mount("#app");
// .use(Notifications)
app.use(store).use(router).mount("#app");
