<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark mb-4">
    <div class="container-fluid" v-if="$store.state.user !== ''">
      <div class="row col-2">
        <div class="col-6 toRight" @click="$router.go(-1)">
          <i class="fa-solid fa-arrow-left-long fa-2x" >
            <div class="navText">Back</div>
          </i>
        </div>
        <div class="col-6 toRight" @click="$router.push('/')">
          <i class="fa-solid fa-house fa-2x" >
            <div class="navText">Home</div>
          </i>
        </div>
      </div>
      <div class="col-6 toRight" style="color: white">
        Current user: {{ $store.state.user }}
      </div>
      <div class="col-2 toRight" @click="logout">
          <i class="fa-solid fa-sign-out fa-2x">
            <div class="navText">Log out</div>
          </i>
      </div>
    </div>
  </nav>
</template>
<script>
import axios from "axios";
import store from "@/store/index";

async function getAccess(refresh) {
  const accessData = {
    refresh: refresh,
  };
  try {
    let response = await axios.post("api/token/refresh/", accessData);
    const access = response.data.access;
    store.commit("setAccess", access);
    return access;
  } catch (error) {
    console.log(error);
  }
}

export default {
  name: "Nav",
  data() {
    return {};
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("clearAuth");
      console.log("Logging out");
      this.$router.push("/login");
    },
    async getUser() {
      const accessToken = await getAccess(this.$store.state.refresh);
      //
      console.log("accessToken at nav:", accessToken);
      //
      if (!this.$store.state.access || this.$store.state.access.length < 1) {
        console.log("User not logged in...");
        return;
      }

      const res = await axios.get("/user/");
      console.log("response", res);
      this.currentUser = res.data;
    },
  },
  mounted() {
    console.log("NAV REFRESH TOKEN:", this.$store.state.refresh);
    this.getUser();
  },
};
</script>

<style scoped>
div.toRight {
  text-align: right;
}
.fa-house {
  color: white;
  padding-left: 15px;
}
.fa-arrow-left-long {
  color: white;
}
.fa-sign-out {
  color: white;
}
.navText {
  color: white;
  font-size: 10px;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
}
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
